<template>
    <div class="accordion" role="tablist">
        <div v-if="modules.ranking_uad.length > 0">
            <b-card no-body class="mb-1" :key="indextr" v-for="(tr, indextr) in modules.ranking_uad">
                <div class="pl-1 pr-1 pt-1">
                    <b-row>
                        <b-col lg="3">
                            <b>{{tr.uad}}</b>
                        </b-col>
                        <b-col lg="3">
                            <b>{{tr.meta}}</b>
                        </b-col>
                        <b-col lg="3">
                            <b-progress
                                :max="max"
                                class="progress-bar-info"
                            >
                                <b-progress-bar
                                    :value="tr.media"
                                    :label="`${tr.media}% ` + ` / ${tr.min}%`"
                                    variant="info"
                                />
                            </b-progress>
                        </b-col>
                        <b-col lg="3">
                            <b-badge variant="light-info" v-show="tr.status == 1">
                                <feather-icon
                                    icon="StarIcon"
                                    
                                />
                                Meta Concluída
                            </b-badge>
                            <b-badge variant="light-warning" v-show="tr.status == 0">
                                <feather-icon
                                    icon="TrendingUpIcon"
                                    
                                />
                                Em Andamento
                            </b-badge>
                        </b-col>
                    </b-row>
                </div>
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle="'accordion-' + tr.id" variant="info" >
                        Ver Ações
                    </b-button>
                </b-card-header>
                <b-collapse :id="'accordion-' + tr.id" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-table-simple
                            striped
                            responsive
                        >
                            <b-thead>
                            <b-tr class="text-center">
                                <b-th>Nome</b-th>
                                <b-th>Total Realizada (%)</b-th>
                                <b-th>Observações(%)</b-th>
                                <b-th>Ultima Atualização</b-th>
                            </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr :key="indextr" v-for="(tr, indextr) in tr.acao">
                                    <b-td>
                                        {{tr.nome}}
                                    </b-td>
                                    <b-td class="text-center">
                                        <b-progress
                                            :max="max"
                                            class="progress-bar-info"
                                        >
                                            <b-progress-bar
                                                :value="tr.porc_concluida"
                                                :label="`${tr.porc_concluida}% ` + ` / 100%`"
                                                variant="info"
                                            />
                                        </b-progress>
                                    </b-td>
                                    <b-td class="text-center">
                                        {{tr.obs}}
                                    </b-td>
                                    <b-td class="text-center">
                                        {{tr.created_at | dateTimeFormat}}
                                    </b-td>
                                    
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-card-body>
                </b-collapse>
            </b-card> 
        </div>
        <div v-else>
            <b-card class="text-center">
                <h2>
                  Encontramos nenhum dado correspondente em nosso sistema!
                </h2>
                <p>
                  <small class="text-muted">Entre em contato com os administradores caso isso for um erro!</small>
                </p>
                <p class="mt-2">
                  <b-img
                    fluid
                    width="300"
                    :src="require('@/assets/images/pages/empty.svg')"
                  />
                </p>
            </b-card>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
  export default {
    components: {
        mapActions,
        mapState
    },
    directives: {
        Ripple
    },
    data() {
      return {
        text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
        value: 100,
        max: 100,
        select_id: 0
      }
    },
    computed: {
        ...mapState("connectAPI", ["modules"])
    },
    methods: {
        fetchRankingUad(){
            this.isLoading = true
            this.$store
            .dispatch("connectAPI/fetchModules", {
                pathAPI: 'ranking_uad',
                paginated: false,
            })
            .then((response) => {
                this.totalRows = response.total
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                this.isLoading = false
            })
        },

        teste(value){
            this.select_id = value
        }
    },
    created(){
        this.fetchRankingUad();
    }
  }
</script>